<template>
  <vx-card class="contained-example-container">
    <div class="header">
      <div class="row">
        <div>
          <h4>{{ title }}</h4>
        </div>
        <!-- <DatepickerRangePopup
          ref="datepickerRangePopup"
          @save="saveRangeInterval"
        />-->
        <div>
          <div slot="actions">
            <span @click="download">
              <vs-icon
                v-tooltip="lang.metrics.download[languageSelected]"
                icon="icon-download"
                icon-pack="feather"
                style="cursor: pointer"
              ></vs-icon>
            </span>
            <!-- <metric-dropdown
              :on-last-week="updateInterval"
              :on-last-month="updateInterval"
              :on-range-select="openRangeInterval"
            />-->
          </div>
        </div>
      </div>
      <div>
        <h6 class="description">{{ description }}</h6>
      </div>
    </div>

    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <vs-table
        max-items="8"
        pagination
        :data="result"
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th
            :key="`${indexth}_`"
            v-for="(_, indexth) in report._extra.th"
            >{{ th(indexth) }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="`${indextr}__`" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].tag">{{ data[indextr].tag }}</vs-td>
            <vs-td :data="data[indextr].count">
              <vs-chip color="primary">{{ data[indextr].count }}</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].chats" v-if="data[indextr].chats">
              <span
                class="truncate"
                :key="`${indexItem}___`"
                v-for="(item, indexItem) in data[indextr].showall
                  ? data[indextr].chats
                  : data[indextr].chats.slice(0, LIMIT)"
              >
                <vs-chip class="truncate mt-1 mb-1 p-0" color="transparent">
                  <vs-chip>
                    <a @click="changeView(item.id)" style="cursor: pointer">
                      {{
                        item.fullname ||
                          lang.attentionRequests.sidenav.anonymousUser[
                            languageSelected
                          ]
                      }}
                    </a>
                  </vs-chip>
                  <vs-chip>{{ item.platform }}</vs-chip>
                </vs-chip>
              </span>
              <vs-divider v-if="data[indextr].chats.length > LIMIT" />
              <div
                @click="data[indextr].showall = true"
                class="mb-2 float-right text-primary"
                style="cursor: pointer"
                v-if="
                  data[indextr].chats.length > LIMIT && !data[indextr].showall
                "
              >
                {{ lang.metrics.showMore[languageSelected] }}
                ({{ data[indextr].chats.length - LIMIT }})
              </div>
              <div
                @click="data[indextr].showall = false"
                class="mb-2 float-right text-primary"
                style="cursor: pointer"
                v-if="
                  data[indextr].chats.length > LIMIT && data[indextr].showall
                "
              >
                {{ lang.metrics.showLess[languageSelected] }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vx-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
const moment = require('moment')
const {
  Parser,
  transforms: { unwind }
} = require('json2csv')

export default {
  name: 'TagsTableV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  data() {
    return {
      Parser,
      unwind,

      LIMIT: 10, // cantidad de elementos a mostrar
      key: new Date().getTime(),

      // interval: {},
      result: [
        {
          text: 'N/A',
          count: 0
        }
      ]
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      if (this.report && this.report.name) {
        const found = this.report.name.find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    download() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      let fields = this.report._extra.th.map((_, index) => this.th(index))
      fields[fields.length - 1] = 'Chat.id'
      fields.push('Chat.platform')
      fields.push('Chat.fullname')
      fields.push('Chat.updatedAt')

      let data = []
      this.result.forEach(element => {
        data.push({})
        data[data.length - 1][fields[0]] = element.tag
        data[data.length - 1][fields[1]] = element.count
        data[data.length - 1]['Chat'] = element.chats
      })

      const transforms = [unwind({ paths: ['Chat'], blankOut: true })]
      const json2csvParser = new Parser({ fields, transforms })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_metric_${this.title}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    },
    th(index) {
      if (!this.report || !this.report._extra || !this.report._extra.th) {
        return 'N/A'
      }
      if (this.report._extra.th[index]) {
        const found = this.report._extra.th[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'N/A'
    },
    async load(report) {
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        this.result = result.data[0]
        this.key = new Date().getTime() // para actualizar el componente
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    },
    changeView(chat) {
      let routeData = this.$router.resolve({
        path: '/attention-requests',
        query: {
          chat
        }
      })
      window.open(routeData.href, '_blank')
    },
    showRow(row) {
      return row.chats && row.user
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>

<style>
.vs-table--tbody {
  z-index: 1 !important;
}
</style>
