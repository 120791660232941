var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"contained-example-container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"row"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),_c('div',[_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('span',{on:{"click":_vm.download}},[_c('vs-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.lang.metrics.download[_vm.languageSelected]),expression:"lang.metrics.download[languageSelected]"}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"icon-download","icon-pack":"feather"}})],1)])])]),_vm._v(" "),_c('div',[_c('h6',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))])])]),_vm._v(" "),_c('div',{staticClass:"p-6 pb-base vs-con-loading__container",attrs:{"id":("div-with-loading-" + (_vm.report._id))}},[_c('vs-table',{attrs:{"max-items":"8","pagination":"","data":_vm.result,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:(indextr + "__")},[_c('vs-td',{attrs:{"data":data[indextr].tag}},[_vm._v(_vm._s(data[indextr].tag))]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].count}},[_c('vs-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(data[indextr].count))])],1),_vm._v(" "),(data[indextr].chats)?_c('vs-td',{attrs:{"data":data[indextr].chats}},[_vm._l((data[indextr].showall
                ? data[indextr].chats
                : data[indextr].chats.slice(0, _vm.LIMIT)),function(item,indexItem){return _c('span',{key:(indexItem + "___"),staticClass:"truncate"},[_c('vs-chip',{staticClass:"truncate mt-1 mb-1 p-0",attrs:{"color":"transparent"}},[_c('vs-chip',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.changeView(item.id)}}},[_vm._v("\n                    "+_vm._s(item.fullname ||
                        _vm.lang.attentionRequests.sidenav.anonymousUser[
                          _vm.languageSelected
                        ])+"\n                  ")])]),_vm._v(" "),_c('vs-chip',[_vm._v(_vm._s(item.platform))])],1)],1)}),_vm._v(" "),(data[indextr].chats.length > _vm.LIMIT)?_c('vs-divider'):_vm._e(),_vm._v(" "),(
                data[indextr].chats.length > _vm.LIMIT && !data[indextr].showall
              )?_c('div',{staticClass:"mb-2 float-right text-primary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){data[indextr].showall = true}}},[_vm._v("\n              "+_vm._s(_vm.lang.metrics.showMore[_vm.languageSelected])+"\n              ("+_vm._s(data[indextr].chats.length - _vm.LIMIT)+")\n            ")]):_vm._e(),_vm._v(" "),(
                data[indextr].chats.length > _vm.LIMIT && data[indextr].showall
              )?_c('div',{staticClass:"mb-2 float-right text-primary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){data[indextr].showall = false}}},[_vm._v("\n              "+_vm._s(_vm.lang.metrics.showLess[_vm.languageSelected])+"\n            ")]):_vm._e()],2):_vm._e()],1)})}}])},[_c('template',{slot:"thead"},_vm._l((_vm.report._extra.th),function(_,indexth){return _c('vs-th',{key:(indexth + "_")},[_vm._v(_vm._s(_vm.th(indexth)))])}),1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }